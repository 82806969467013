.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  width: 100%;
  height: calc(100% - 4.8rem);
  display: flex;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
}

.main_top_container {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  padding: 0 1rem;
}

.menu_icon {
  font-size: 3rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}

.text_bread_crumb {
  font-size: 1.3rem;
  line-height: 2.1rem;
}

.content {
  width: 100%;
  height: calc(100% - 3rem);
}

.wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 2;

  position: absolute;
  top: 0;
  left: 0;
}
