* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  /* font-family: "Roboto", sans-serif !important;
  font-weight: 400; */
  font-size: 1.4rem !important;
  color: #1d1d1f;
  letter-spacing: normal;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.bg-white {
  background-color: #fff;
}

.text-center {
  text-align: center;
}

.text-13 {
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: rgba(0, 0, 0, 0.85);
}

.text-medium-13 {
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: rgba(0, 0, 0, 0.65);
}

.text-medium-12 {
  font-size: 1.2rem;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.65);
}

.text-medium-11 {
  font-size: 1.1rem;
  line-height: 1.9rem;
  color: rgba(0, 0, 0, 0.65);
}

.text-secondary-13 {
  font-size: 1.3rem;
  line-height: 2.1rem;
  color: rgba(0, 0, 0, 0.45);
}

.text-secondary-12 {
  font-size: 1.2rem;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.45);
}

.text-secondary-11 {
  font-size: 1.1rem;
  line-height: 1.9rem;
  color: rgba(0, 0, 0, 0.45);
}

.text-secondary-10 {
  font-size: 1rem;
  line-height: 1.8rem;
  color: rgba(0, 0, 0, 0.45);
}
.text-secondary-9 {
  font-size: 0.9rem;
  line-height: 1.7rem;
  color: rgba(0, 0, 0, 0.45);
}

.font-600 {
  font-weight: 600;
}

.space-between {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
}

.my-1 {
  margin-top: 1rem;
}

.my-05 {
  margin-top: 0.5rem;
}
