.container {
  width: 100%;
  height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: #25282f;
  border-bottom-color: #25282f; */
  padding: 0 1.6rem;
}

.logo_container {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.logo_container:hover {
  cursor: pointer;
}

.logo_container > img {
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
}

.logo_container > span {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.3rem;
  color: #fff;
}

.avatar:hover {
  cursor: pointer;
}

.avatar_menu_container {
  width: 25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.avatar_menu_item {
  display: grid;
  grid-template-columns: 15% 73%;
  gap: 0.8rem;
  align-items: center;
}

.avatar_menu_item:hover {
  cursor: pointer;
}

.change_lan {
  width: 100%;
  display: flex;
  align-items: center;
}
