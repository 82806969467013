.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.logo_container {
  width: 10rem;
  height: 10rem;
  border-radius: 5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: #fff;
}

.image {
  width: 5rem;
  object-fit: contain;
}

.loading_icon {
  position: absolute;
  font-size: 10.5rem;
  color: #69c0ff;
  z-index: -1;
}

.text {
  margin-top: 1.6rem;
}
