.container {
  width: 32rem;
  height: calc(100vh - 4.8rem);
  background-color: #fff;

  position: absolute;
  left: 0rem;
  top: 4.8rem;
  z-index: 11;
  box-shadow: 0 2.7px 9px rgb(0 0 0 / 13%), 0 9.4px 24px rgb(0 0 0 / 9%),
    0 21.8px 43px rgb(0 0 0 / 8%);

  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.menu_item_container {
  padding: 1rem;
  display: grid;
  grid-template-columns: 10% 88%;
  align-items: center;
  gap: 0.4rem;

  color: rgba(0, 0, 0, 0.45);
  font-size: 1.4rem;
  border-radius: 0.6rem;
}

.menu_item_container:hover {
  cursor: pointer;
}

.menu_item_selected {
  background-color: #1b9cfc;
  color: #fff !important;
}

.icon {
  font-size: 2rem;
  font-weight: 700;
}

.text {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.2rem;
}

.text_selected {
  color: #fff !important;
  font-weight: 700 !important;
}

.close_btn {
  width: 2.4rem;
  height: 2.4rem;
  background-color: #69707d;
  color: #fff;

  position: absolute;
  top: 1rem;
  left: 33rem;

  display: flex;
  align-items: center;
  justify-content: center;
  /* z-index: 5; */
}

.close_btn:hover {
  cursor: pointer;
}

.more_text {
  font-size: 1rem;
  margin-left: 0.5rem;
  color: rgba(0, 0, 0, 0.45);
}
