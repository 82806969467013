.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
}

.form_control {
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
}

.form_control > label {
  font-weight: 500;
  color: #1a1c21;
  word-wrap: break-word !important;
  word-break: break-word;
  font-size: 1.2rem;
  line-height: 2rem;
}
