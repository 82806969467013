@import "~antd/dist/antd.css";
@import "react-perfect-scrollbar/dist/css/styles.css";
@import "/node_modules/react-grid-layout/css/styles.css";
@import "/node_modules/react-resizable/css/styles.css";

.ant-modal-body {
  padding: 0;
}

.ant-collapse-content-box {
  padding: 0 !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 10px 16px;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 16px;
}

.ant-drawer-body {
  padding: 0 !important;
}

.ant-drawer-header {
  padding: 1.6rem !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 1.2rem 0 1.2rem 1.6rem;
}

/* Divider */
.ant-divider-horizontal {
  margin: 1rem 0;
}

/* Tab */
.ant-tabs-nav {
  padding: 0 1.6rem;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0;
}

/*Context menu*/
.react-contextmenu {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.6rem;
  color: #373a3c;
  font-size: 13px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
  color: #878a8c;
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu
  > .react-contextmenu-item:after {
  content: "▶";
  display: inline-block;
  position: absolute;
  right: 7px;
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}
